export async function getUserCredentials(authToken) {
    if (authToken) {
        try {
            const response = await fetch('https://app.e-waybill.in/app/cred', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Auth-Token': authToken,
                },
            });
            if (response.ok) {
                const users = await response.json()
                const pageUrl = window.location.hash
                if (pageUrl === "#/login" || pageUrl === "#/otp") {
                    localStorage.setItem("username-otp", users[0].tenant.name)
                } else {
                    localStorage.setItem("username-ewb", users[0].tenant.name)
                }
                return await users
            }
        } catch (error) {
            console.error('Error:', error);
        }

    }
    return [];
}