import { format } from 'date-fns'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faCircleInfo } from "@fortawesome/free-solid-svg-icons"
import { ThreePulseCircles } from "../../../Animation/Animation"
import ReactPaginate from "react-paginate"
import { DownloadButton } from "../../../Common/Button"
import { useState, useEffect } from 'react'
import { utils, writeFileXLSX } from 'xlsx'

const Cards = ({ requestsFromLS, handleStopAndDeleteProcess }) => {

    const [cardState, setCardState] = useState({
        currentPage: 0,
        currentTime: new Date()
    })
    const PER_PAGE = 5
    const offset = cardState.currentPage * PER_PAGE
    const pageCount = Math.ceil(requestsFromLS.length / PER_PAGE)
    const formattedTime = format(cardState.currentTime, 'dd/MM hh:mm:ss a')

    function handlePageClick({ selected: selectedPage }) {
        setCardState((prev) => ({
            ...prev,
            currentPage: selectedPage
        }))
    }

    const handleDownloadExcel = (requestId) => {
        const eachRequest = requestsFromLS.find(item => item.r.requestId === requestId)
        const newArray = eachRequest.r.results?.map(({ trn, status, names }) => ({
            trn,
            status,
            "english name": names[0].english,
            "arabic name": names[0].arabic
        }))
        const date = format(new Date(), 'yyyy-MM-dd-HH-mm')
        const ws = utils.json_to_sheet(newArray)
        const wb = utils.book_new()
        utils.book_append_sheet(wb, ws, "Data")
        writeFileXLSX(wb, `${eachRequest.r.state}_TRN_${date}.xlsx`)
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setCardState((prev) => ({
                ...prev,
                currentTime: new Date()
            }))
        }, 1000)
        return () => clearInterval(interval)
    }, [])

    return (
        <div className="col-8">
            <div className="row justify-content-between" style={{ height: "fit-content" }}>
                <div className="alert alert-warning col me-2 align-content-center text-center">
                    2 Jobs will be run at a time, if there are more than 2 jobs then it will wait in queue, please wait..
                </div>
                <div className="alert alert-primary col align-content-center text-center">
                    System Time : {formattedTime}
                </div>
            </div>
            {
                requestsFromLS
                    .sort((a, b) => b.r.startTime - a.r.startTime)
                    .slice(offset, offset + PER_PAGE)
                    .map((item, index) => {
                        const country = item.r.state
                        const reqId = item.r.requestId
                        const inProcess = item.r.inProcess
                        const queuePosition = item.r.queuePosition
                        const queueSize = item.r.queueSize
                        const startTime = item.r.startTimeStr
                        const endTime = item.r.endTimeStr
                        const completed = item.r.completed
                        const outOf = item.r.outOf
                        const isButtonDisabled = item.r.results.length === 0
                        return (
                            <div className="row card mb-2" key={index + offset}>
                                <div className="d-flex card-header justify-content-between">
                                    <div>{country} - {reqId}</div>
                                    {inProcess && <div className="text-success">Queue {queuePosition} of {queueSize}</div>}
                                    <div>Start: {startTime}</div>
                                    {!inProcess && <div> End: {endTime}</div>}
                                </div>
                                <div className="card-body">
                                    <div className="d-flex align-items-center mb-2">
                                        {inProcess ? (
                                            <>
                                                <ThreePulseCircles />
                                                <span className="ms-2">Processing</span>
                                            </>
                                        ) : (
                                            <>
                                                <FontAwesomeIcon icon={faCheck} className="me-2" />
                                                <span>Completed</span>
                                            </>
                                        )}
                                        <span className="text-success ms-2">{completed}</span>
                                        <span className="mx-1">/</span>
                                        <span className="text-info">{outOf}</span>
                                    </div>
                                    {!inProcess && (
                                        <div className="alert alert-info">
                                            <FontAwesomeIcon icon={faCircleInfo} /> Kindly download the Excel result now
                                        </div>
                                    )}
                                </div>
                                <div className="d-flex card-header justify-content-end border border-top">
                                    <button
                                        type="button"
                                        className="me-2 btn btn-danger"
                                        onClick={() => handleStopAndDeleteProcess(reqId, country)}
                                    >
                                        Stop & Remove
                                    </button>
                                    <DownloadButton
                                        onClick={() => handleDownloadExcel(reqId)}
                                        disabled={isButtonDisabled}
                                    >
                                        Excel Download
                                    </DownloadButton>
                                </div>
                            </div>
                        )
                    })
            }
            {requestsFromLS.length !== 0 &&
                <div className="mb-5 pb-2">
                    <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-center"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active primary"}
                    />
                </div>}
        </div>
    )
}
export default Cards