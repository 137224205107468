import { useContext, useEffect, useState, useRef } from "react"
import { utils, writeFileXLSX } from 'xlsx'
import { NavLink, useNavigate } from "react-router-dom"
import { CredentialsContext } from "../API/CredentialsContext"
import { Actions } from "../Reducer/reducer"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"
import { ExcelHeaderArray } from "../Data/excelTemplateHeader"
import { useLocation } from "react-router-dom"
import Heading from "./Heading"
import {ToastContainer} from "react-toastify/unstyled";
import {toast} from "react-toastify";

const Navbar = () => {

    const navigate = useNavigate()
    const { state, dispatch } = useContext(CredentialsContext)
    const pageUrl = window.location.hash
    const authToken = localStorage.getItem('authToken')
    const [headerState, setHeaderState] = useState({
        isAdmin: false,
        permissions: []
    })
    const location = useLocation()
    const isAdmin = headerState.isAdmin
    const username = localStorage.getItem('username-ewb')

    const paths = {
        partA: ['/e-waybill/consignor', '/duplicate', '/rto'],
        partB: ['/part-b/update', '/part-b/consolidate', '/multi-vehicle'],
        print: ['/print/ewb', '/print/multi-ewb'],
        tools: ['/tools/change-transporter', '/tools/check-gstin', '/tools/check-return', '/tools/check-distance'],
        expiryExtend: ['/expiry/expiring', '/expiry/extend', '/expiry/extend-v2'],
        autoExtend: ['/auto-extend/reports', '/auto-extend/upload'],
        administration: ['/admin/home']
    }

    const isPathActive = (paths) => paths.some(path => location.pathname === path)

    const isPartAActive = isPathActive(paths.partA)
    const isPartBActive = isPathActive(paths.partB)
    const isPrintActive = isPathActive(paths.print)
    const isToolsActive = isPathActive(paths.tools)
    const isExpiryExtendActive = isPathActive(paths.expiryExtend)
    const isAutoExtendActive = isPathActive(paths.autoExtend)

    const permissions = {
        partADropdown: ["GENERATE_PARTA", "GENERATE_DUPLICATE", "GENERATE_RTO", "VIEW_EWB_OPS"],
        partAGenerate: ["GENERATE_PARTA", "VIEW_EWB_OPS"],
        duplicate: ["GENERATE_DUPLICATE", "VIEW_EWB_OPS"],
        rto: ["GENERATE_RTO", "VIEW_EWB_OPS"],
        partBDropdown: ["UPDATE_PARTB", "VIEW_EWB_OPS"],
        expiryExtend: ["EXTEND_EWAYBILL", "VIEW_EWB_OPS"],
        autoExtend: ["AUTO_EXTEND_EWAYBILL", "VIEW_EWB_OPS"]
    }

    const hasPermission = (permissions) =>
        permissions.some(permission => headerState.permissions.includes(permission)) || isAdmin

    const showPartADropdown = hasPermission(permissions.partADropdown)
    const showPartAGenerate = hasPermission(permissions.partAGenerate)
    const showDuplicate = hasPermission(permissions.duplicate)
    const showRTO = hasPermission(permissions.rto)
    const showPartBDropdown = hasPermission(permissions.partBDropdown)
    const showExpiryExtend = hasPermission(permissions.expiryExtend)
    const showAutoExtend = hasPermission(permissions.autoExtend)

    const dropdownRefs = {
        partA: useRef(null),
        partB: useRef(null),
        print: useRef(null),
        tools: useRef(null),
        expiry: useRef(null),
        autoExtend: useRef(null),
        admin: useRef(null)
    }

    const closeDropdown = (ref) => {
        if (ref.current) {
            ref.current.click()
        }
    }

    const handleLogout = () => {
        localStorage.removeItem('authToken')
        localStorage.removeItem('isAdmin')
        localStorage.removeItem('username-ewb')
        dispatch({ type: Actions.ClearCredentials })
        navigate('/')
    }

    const handleDownloadExcelTemplates = (filename, index) => {
        const ws = utils.json_to_sheet(ExcelHeaderArray[index])
        const wb = utils.book_new()
        utils.book_append_sheet(wb, ws, "Data")
        writeFileXLSX(wb, `${filename}.xlsx`)
    }

    useEffect(() => {
        const handleAdminCheck = async (r) => {
            try {
                if (!authToken) return
                dispatch({ type: Actions.ShowLoading })
                const response = await fetch(`https://app.e-waybill.in/app/user/me`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Auth-Token': authToken,
                    },
                })
                const data = await response.json()
                setHeaderState((prev) => ({
                    ...prev,
                    isAdmin: data.isAdmin,
                    permissions: data.permissions
                }))
                localStorage.setItem('isAdmin', data.isAdmin)
            }
            catch (error) { console.error('Error in fetching response from me api:', error) }
            finally {
                dispatch({ type: Actions.HideLoading })
            }
        }
        handleAdminCheck().then()
    }, [authToken, dispatch])

    return (
        <nav className="navbar-expand-lg border-bottom sticky-top bg-light py-2 px-2">
            <div className="d-flex justify-content-between">
                <Heading username={username}/>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <FontAwesomeIcon icon={faBars} />
                </button>
                {
                    pageUrl !== '#/' && pageUrl !== "" &&
                    <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                        <ul className="list-unstyled navbar-nav ms-1 d-flex flex-wrap">
                            {
                                showPartADropdown
                                &&
                                <li className="me-2 nav-link dropdown">
                                    <button
                                        className={`dropdown-toggle text-decoration-none btn ${isPartAActive ? 'bg-primary text-white' : 'text-primary'}`}
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        ref={dropdownRefs.partA}
                                        type="button"
                                    >
                                        Part A
                                    </button>
                                    <ul className="dropdown-menu">
                                        {
                                            showPartAGenerate &&
                                            <li>
                                                <NavLink
                                                    className="text-decoration-none dropdown-item"
                                                    to='/e-waybill/consignor'
                                                    onClick={() => closeDropdown(dropdownRefs.partA)}
                                                >
                                                    Part A (Generate)
                                                </NavLink>
                                            </li>
                                        }
                                        {
                                            showDuplicate &&
                                            <li>
                                                <NavLink
                                                    className="text-decoration-none dropdown-item"
                                                    to='/duplicate'
                                                    onClick={() => closeDropdown(dropdownRefs.partA)}
                                                >
                                                    Duplicate EWaybill
                                                </NavLink>
                                            </li>
                                        }
                                        {
                                            showRTO &&
                                            <li>
                                                <NavLink
                                                    className="text-decoration-none dropdown-item"
                                                    to='/rto'
                                                    onClick={() => closeDropdown(dropdownRefs.partA)}
                                                >
                                                    RTO EWaybill
                                                </NavLink>
                                            </li>
                                        }
                                    </ul>
                                </li>
                            }
                            <li className="me-2 nav-link">
                                <NavLink
                                    className="text-decoration-none btn border-0"
                                    to='/api-requests'
                                    style={({ isActive }) => ({ color: isActive ? 'white' : 'rgb(13, 110, 253)', background: isActive && "rgb(13, 110, 253)" })}
                                >
                                    API Calls
                                </NavLink>
                            </li>
                            {
                                showPartBDropdown &&
                                <li className="me-2 nav-link dropdown">
                                    <button
                                        className={`dropdown-toggle text-decoration-none btn ${isPartBActive ? 'bg-primary text-white' : 'text-primary'}`}
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        ref={dropdownRefs.partB}
                                        type="button"
                                    >
                                        Part B
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li>
                                            <NavLink
                                                className="text-decoration-none dropdown-item"
                                                to='/part-b/update'
                                                onClick={() => closeDropdown(dropdownRefs.partB)}
                                            >
                                                Part-B (Update)
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                className="text-decoration-none dropdown-item"
                                                to='/part-b/consolidate'
                                                onClick={() => closeDropdown(dropdownRefs.partB)}
                                            >
                                                Part-B(Consolidate)
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                className="text-decoration-none dropdown-item"
                                                to='/multi-vehicle'
                                                onClick={() => closeDropdown(dropdownRefs.partB)}
                                            >
                                                Multi Vehicle Update
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                            }
                            <li className="me-2 nav-link dropdown">
                                <button
                                    className={`dropdown-toggle text-decoration-none btn ${isPrintActive ? 'bg-primary text-white' : 'text-primary'}`}
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    ref={dropdownRefs.print}
                                    type="button"
                                >
                                    Print
                                </button>
                                <ul className="dropdown-menu">
                                    <li>
                                        <NavLink
                                            className="text-decoration-none dropdown-item"
                                            to='/print/ewb'
                                            onClick={() => closeDropdown(dropdownRefs.print)}
                                        >
                                            Print EWB
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            className="text-decoration-none dropdown-item"
                                            to='/print/multi-ewb'
                                            onClick={() => closeDropdown(dropdownRefs.print)}
                                        >
                                            Print Multiple EWB
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                            <li className="me-2 nav-link dropdown">
                                <button
                                    className={`dropdown-toggle text-decoration-none btn ${isToolsActive ? 'bg-primary text-white' : 'text-primary'}`}
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    ref={dropdownRefs.tools}
                                    type="button"
                                >
                                    Tools
                                </button>
                                <ul className="dropdown-menu">
                                    <li>
                                        <NavLink
                                            className="text-decoration-none dropdown-item"
                                            to='/tools/change-transporter'
                                            onClick={() => closeDropdown(dropdownRefs.tools)}
                                        >
                                            Change Transporter
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            className="text-decoration-none dropdown-item"
                                            to='/tools/check-gstin'
                                            onClick={() => closeDropdown(dropdownRefs.tools)}
                                        >
                                            Check GSTINs
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            className="text-decoration-none dropdown-item"
                                            to='/tools/check-return'
                                            onClick={() => closeDropdown(dropdownRefs.tools)}
                                        >
                                            Check Return Status
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            className="text-decoration-none dropdown-item"
                                            to='/tools/check-distance'
                                            onClick={() => closeDropdown(dropdownRefs.tools)}
                                        >
                                            Check Pin-to-Pin Distance
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                            {
                                showExpiryExtend &&
                                <li className="me-2 nav-link dropdown">
                                    <button
                                        className={`dropdown-toggle text-decoration-none btn ${isExpiryExtendActive ? 'bg-primary text-white' : 'text-primary'}`}
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        ref={dropdownRefs.expiry}
                                        type="button"
                                    >
                                        Expiry/Extend
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li>
                                            <NavLink
                                                className="text-decoration-none dropdown-item"
                                                to='/expiry/expiring'
                                                onClick={() => closeDropdown(dropdownRefs.expiry)}
                                            >
                                                Expiring EWB
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                className="text-decoration-none dropdown-item"
                                                to='/expiry/extend'
                                                onClick={() => closeDropdown(dropdownRefs.expiry)}
                                            >
                                                Extend EWB
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                className="text-decoration-none dropdown-item"
                                                to='/expiry/extend-v2'
                                                onClick={() => closeDropdown(dropdownRefs.expiry)}
                                            >
                                                ExtendEWB (In Warehouse)
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                            }
                            {
                                showAutoExtend &&
                                <li className="me-2 nav-link dropdown">
                                    <button
                                        className={`dropdown-toggle text-decoration-none btn ${isAutoExtendActive ? 'bg-primary text-white' : 'text-primary'}`}
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        ref={dropdownRefs.autoExtend}
                                        type="button"
                                    >
                                        Auto Extend
                                    </button>
                                    <ul className="dropdown-menu">
                                        <li>
                                            <NavLink
                                                className="text-decoration-none dropdown-item"
                                                to='/auto-extend/reports'
                                                onClick={() => closeDropdown(dropdownRefs.autoExtend)}
                                            >
                                                Reports
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink
                                                className="text-decoration-none dropdown-item"
                                                to='/auto-extend/upload'
                                                onClick={() => closeDropdown(dropdownRefs.autoExtend)}
                                            >
                                                Upload</NavLink>
                                        </li>
                                    </ul>
                                </li>
                            }
                            <li className="me-2 nav-link dropdown">
                                <button
                                    className="dropdown-toggle text-decoration-none btn text-primary"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    type="button"
                                >
                                    Template
                                </button>
                                <ul className="dropdown-menu">
                                    <li>
                                        <NavLink
                                            className="text-decoration-none dropdown-item bg-light text-black"
                                            onClick={() => handleDownloadExcelTemplates("e-waybill-template", 0)}>
                                            Part-A
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            className="text-decoration-none dropdown-item bg-light text-black"
                                            onClick={() => handleDownloadExcelTemplates("extend", 1)}>
                                            Extend
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                            {
                                isAdmin &&
                                <li className="me-2 nav-link">
                                    <NavLink
                                        className="text-decoration-none btn border-0"
                                        to='/admin/home'
                                        style={({ isActive }) => ({ color: isActive ? 'white' : 'rgb(13, 110, 253)', background: isActive && "rgb(13, 110, 253)" })}
                                    >
                                        Admin
                                    </NavLink>
                                </li>
                            }
                            <li className="me-2 nav-link">
                                <button
                                    type="button"
                                    className="text-decoration-none btn text-primary">
                                    <NavLink
                                        className="text-decoration-none"
                                        onClick={handleLogout}>
                                        Logout
                                    </NavLink>
                                </button>
                            </li>
                        </ul>
                    </div>
                }
            </div>
        </nav>
    )
}
export default Navbar