import {useEffect, useState} from "react"
import Form from "./Components/Form"
import Cards from "./Components/Cards"

const Country = () => {

    const [vatState, setVatState] = useState({
        country: "",
        trns: [],
        response: "",
        inProcess: false
    })
    const [requests, setRequests] = useState([])
    const [requestsFromLS, setRequestFromLS] = useState(JSON.parse(localStorage.getItem("VAT")) || [])
    const pageUrl = window.location.hash

    const handleState = (event) => {
        const {name, value} = event.target
        setVatState(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const handleValidate = async () => {
        try {
            const payload = {
                trns: [...new Set((vatState.trns.split('\n').filter(item => item !== '')))]
            }
            if (payload) {
                const state = vatState.country
                const url = `https://validate-vat.e-waybill.in/api/vat/validate2?state=${state}`
                const startTime = new Date().getTime()
                const startTimeStr = new Date().toLocaleString('en-IN')
                const response = await fetch(url, {
                    method: "POST",
                    body: JSON.stringify(payload)
                })
                let r = await response.json()
                r = {...r, state, startTime, startTimeStr}

                setVatState(prevState => ({
                    ...prevState,
                    response: r,
                    inProcess: r.queueSize !== 0 || r.completed !== r.outOf || r.results.length === 0,
                    startTime,
                    startTimeStr
                }))
            }
        } catch (e) {
            console.error("error occured during validation", e)
        }
    }  

    const handleStopAndDeleteProcess = async (requestId, state) => {
        try {
            const url = `https://validate-vat.e-waybill.in/api/vat?request_id=${requestId}&state=${state}`
            await fetch(url, {
                method: "DELETE"
            })
            const updatedData = requests.filter(item => item.r.requestId !== requestId)
            setRequests(updatedData)
            localStorage.setItem("VAT", JSON.stringify(updatedData))
            setRequestFromLS(JSON.parse(localStorage.getItem("VAT")))
            console.log("process stopped and deleted")
        } catch (e) {
            console.error("Error in stopping process", e)
        }
    }

    useEffect(() => {
        localStorage.setItem("VAT", JSON.stringify(requests))
        setRequestFromLS([...requests])
    }, [requests])

    useEffect(() => {
        const country = pageUrl.split('/').slice(-1)[0].toUpperCase()
        setVatState((prev) => ({
            ...prev,
            country: country
        }))
    }, [pageUrl])

    useEffect(() => {
        const interval = setInterval(() => {
            setVatState((prev) => ({
                ...prev,
                currentTime: new Date()
            }))
        }, 1000)
        return () => clearInterval(interval)
    }, [])
  
    return (
        <div className="row p-2 mx-0 mt-4">
            <Form
                handleState={handleState}
                handleValidate={handleValidate}
                vatState={vatState}
            />
            <Cards
                handleStopAndDeleteProcess={handleStopAndDeleteProcess}
                requestsFromLS={requestsFromLS}
            />
        </div>
    )
}
export default Country