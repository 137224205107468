import { useNavigate } from "react-router-dom"
import { PrimaryNormalButton } from "../../Common/Button"
import Heading from "../../Common/Heading"

const Navbar = () => {

    const navigate = useNavigate()
    const pageUrl = window.location.hash
    const username = localStorage.getItem("username-otp")

    const logout = () => {
        localStorage.removeItem('authToken-otp')
        localStorage.removeItem('username-otp')
        navigate('/login')
    }

    return (
        <nav className="navbar-expand-lg border-bottom sticky-top bg-light py-2 px-2">
            <div className="d-flex justify-content-between">
                <Heading username={username} />
                {pageUrl === "#/otp" &&
                    <div className="d-flex justify-content-end">
                        <PrimaryNormalButton className="btn btn-primary">OTP</PrimaryNormalButton>
                        <button className="btn text-primary" onClick={logout}>Logout</button>
                    </div>
                }
            </div>
        </nav>
    )
}
export default Navbar