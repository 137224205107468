import logo from '../Images/logo-365build.png'

const Heading = ({ username }) => {

    const pageUrl = window.location.hash
    const isLoginPage = pageUrl === "#/" || pageUrl === "#/login" || pageUrl === ""

    return (
        <div className="navbar-brand">
            <div className="d-flex align-items-center">
                <img src={logo} width="60" height="50" alt="logo" />
                <h3 className="text-muted ms-4 fs-4 pt-2">
                    EWaybill {username && !isLoginPage ? `- ${username}` : ""}
                </h3>
            </div>
        </div>
    )
}
export default Heading