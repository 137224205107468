import { HashRouter, Route, Routes } from "react-router-dom"
import Navbar from "./Common/Navbar"
import Footer from "./Common/Footer"
import { RequireAuth } from "./RequireAuth"
import { LoadingCircle } from './Animation/Animation'
import APICalls from './Pages/Other/APICalls/Index'
import Administration from "./Pages/Admin/Index"
import Update from './Pages/PartB/Update/Index'
import EWB from './Pages/Print/EWB/Index'
import ChangeTransporter from './Pages/Tools/ChangeTransporter/Index'
import ExpiringEWB from './Pages/Extend/ExpiringEWB/Index'
import Settings from './Pages/Other/Settings/Index'
import Duplicate from './Pages/PartA/Duplicate/Index'
import RTO from './Pages/PartA/RTO/Index'
import MVU from './Pages/PartB/MVU/Index'
import MultipleEWB from './Pages/Print/MultipleEWB/Index'
import CheckGSTINS from './Pages/Tools/CheckGSTINS/Index'
import CheckReturnStatus from './Pages/Tools/CheckReturnStatus/Index'
import ExtendEWB from './Pages/Extend/ExtendEWB/Index'
import ExtendEWBInWarehouse from './Pages/Extend/ExtendEWBInWarehouse/Index'
import Reports from './Pages/AutoExtend/Reports/Index'
import Upload from './Pages/AutoExtend/Upload/Index'
import Login from './Pages/Other/Login'
import Generate from './Pages/PartA/Generate/Index'
import { getUserCredentials } from "./API/GetUserCreds"
import { initialState, reducer, Actions } from './Reducer/reducer'
import { useEffect, useReducer } from 'react'
import { CredentialsContext } from "./API/CredentialsContext"
import CheckDistance from "./Pages/Tools/CheckDistance/Index";
import {ToastContainer} from "react-toastify";
import {toast} from "react-toastify";

const EwbApp = () => {
    const [state, dispatch] = useReducer(reducer, initialState)

    useEffect(() => {
        const auth = localStorage.getItem('authToken')

        if (auth) {
            getUserCredentials(auth).then(creds => {
                dispatch({ type: Actions.UpdateCredentials, payload: creds })
            })
        }
    }, [])
    useEffect(() => {
        if(state.notify){
            toast(state.notify)
            dispatch({type: Actions.Notify, payload: null})
        }
    }, [state.notify])
    return (
        <div className={"container-fluid"}>
            <ToastContainer />
            <CredentialsContext.Provider value={{ state, dispatch }}>
                <HashRouter>
                    <Navbar />
                    <Routes>
                        <Route exact path='/' element={<Login />} />
                        <Route exact path='/e-waybill/consignor' element={
                            <RequireAuth>
                                <Generate />
                            </RequireAuth>
                        } />
                        <Route exact path='/duplicate' element={
                            <RequireAuth>
                                <Duplicate />
                            </RequireAuth>
                        } />
                        <Route exact path='/rto' element={
                            <RequireAuth>
                                <RTO />
                            </RequireAuth>
                        } />
                        <Route exact path='/api-requests' element={
                            <RequireAuth>
                                <APICalls />
                            </RequireAuth>
                        } />
                        <Route exact path='/part-b/update' element={
                            <RequireAuth>
                                <Update />
                            </RequireAuth>
                        } />
                        <Route exact path='/part-b/consolidate' element={
                            <RequireAuth>
                                <Update />
                            </RequireAuth>
                        } />
                        <Route exact path='/multi-vehicle' element={
                            <RequireAuth>
                                <MVU />
                            </RequireAuth>
                        } />
                        <Route exact path='/print/ewb' element={
                            <RequireAuth>
                                <EWB />
                            </RequireAuth>
                        } />
                        <Route exact path='/print/multi-ewb' element={
                            <RequireAuth>
                                <MultipleEWB />
                            </RequireAuth>
                        } />
                        <Route exact path='/tools/change-transporter' element={
                            <RequireAuth>
                                <ChangeTransporter />
                            </RequireAuth>
                        } />
                        <Route exact path='/tools/check-gstin' element={
                            <RequireAuth>
                                <CheckGSTINS />
                            </RequireAuth>
                        } />
                        <Route exact path='/tools/check-return' element={
                            <RequireAuth>
                                <CheckReturnStatus />
                            </RequireAuth>
                        } />
                        <Route exact path='/tools/check-distance' element={
                            <RequireAuth>
                                <CheckDistance />
                            </RequireAuth>
                        } />
                        <Route exact path='/expiry/expiring' element={
                            <RequireAuth>
                                <ExpiringEWB />
                            </RequireAuth>
                        } />
                        <Route exact path='/expiry/extend' element={
                            <RequireAuth>
                                <ExtendEWB />
                            </RequireAuth>
                        } />
                        <Route exact path='/expiry/extend-v2' element={
                            <RequireAuth>
                                <ExtendEWBInWarehouse />
                            </RequireAuth>
                        } />
                        <Route exact path='/auto-extend/reports' element={
                            <RequireAuth>
                                <Reports />
                            </RequireAuth>
                        } />
                        <Route exact path='/auto-extend/upload' element={
                            <RequireAuth>
                                <Upload />
                            </RequireAuth>
                        } />
                        <Route exact path='/admin/home' element={
                            <RequireAuth>
                                <Administration />
                            </RequireAuth>
                        } />
                        <Route exact path='/settings' element={
                            <RequireAuth>
                                <Settings />
                            </RequireAuth>
                        } />
                    </Routes>
                    <Footer />
                    {state.loading && <LoadingCircle />}
                </HashRouter>
            </CredentialsContext.Provider>
        </div>
    )
}
export default EwbApp